import { ref, watch } from 'vue';

const darkMode = ref(false);

watch(darkMode, () => {
    console.log('darkMode changed' + darkMode.value);
    // Inertia.patch('/user', {
    //     theme: darkMode.value ? 'dark' : 'light',
    // })
});

export function useDarkMode() {
    return {
        darkMode,
    };
}
