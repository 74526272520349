<script setup>
import { ref, watch, computed, useSlots } from 'vue';
import IconDollarSign from '~icons/fa6-solid/dollar-sign';
import WinClipboard from '../WinClipboard.vue';

const emit = defineEmits(['update:modelValue', 'arrowDown', 'arrowUp']);

const props = defineProps({
    name: String,
    label: String,
    placeholder: {
        required: false,
        //type: [String | Number],
        default: '',
    },
    error: [String, Boolean],
    required: {
        type: Boolean,
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    textArea: {
        type: Boolean,
        default: false,
    },
    inputType: {
        type: String,
        default: 'text', // text || date || password || number
    },
    format: {
        type: String,
        default: '', //currency (type must be number)
    },
    showCopy: {
        type: Boolean,
        default: false,
    },
    maxlength: {
        type: Number,
        default: null,
    },
});

const slots = useSlots();

const hasSlot = (name) => !!slots[name];

const inputValue = ref(props.modelValue);

const hasError = computed(() => {
    return props.error; //!props.readOnly && !props.disabled && props.error;
});

const showFormatSymbol = computed(() => {
    return props.format === 'currency' && props.inputType === 'number';
});

watch(
    () => props.modelValue,
    () => {
        inputValue.value = props.modelValue;
    }
);

watch(inputValue, () => {
    emit('update:modelValue', inputValue.value);
});

const textAreaField = ref(null);

const focusInput = () => {
    if (props.textArea) {
        //was having trouble with this and the @mention code not working until I put a significant delay here (more than just nextTick())
        setTimeout(() => {
            textAreaField.value.focus();
        }, 100);
    }
};

defineExpose({ focusInput });
</script>

<template>
    <label
        :for="name"
        class="block text-sm text-gray-700 dark:text-white"
        :class="required || readOnly ? 'font-bold' : 'font-medium'"
    >
        {{ label }}
        <span v-if="required && !readOnly">*</span>
    </label>
    <div class="relative">
        <!--left icon-->
        <div
            v-if="!readOnly && hasSlot('left')"
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
            <slot name="left"></slot>
        </div>
        <div
            v-else-if="!readOnly && showFormatSymbol"
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
            <icon-dollar-sign class="h-4 w-4 text-gray-500" />
        </div>
        <div v-if="!readOnly">
            <textarea
                v-if="textArea"
                :id="name"
                ref="textAreaField"
                v-model="inputValue"
                :disabled="disabled"
                :placeholder="placeholder"
                :name="name"
                :maxlength="maxlength"
                :class="[
                    hasError ? 'border-red-500' : 'border-gray-300 dark:border-gray-500',
                    hasSlot('left') ? 'pl-10' : '',
                    hasSlot('right') ? 'pr-10' : '',
                ]"
                class="mt-1 block h-24 w-full rounded-md text-sm shadow-sm placeholder:text-xs placeholder:leading-loose placeholder:text-gray-400 focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-600"
                @keydown.up="emit('arrowUp')"
                @keydown.down="emit('arrowDown')"
            />
            <input
                v-else
                :id="name"
                v-model="inputValue"
                :disabled="disabled"
                :type="inputType"
                :name="name"
                v-bind="$attrs"
                :placeholder="placeholder"
                :maxlength="maxlength"
                :class="[
                    hasError ? 'border-red-500' : 'border-gray-300 dark:border-gray-500',
                    hasSlot('left') || showFormatSymbol ? 'pl-10' : '',
                    hasSlot('right') ? 'pr-10' : '',
                ]"
                class="mt-1 block w-full rounded-md text-sm shadow-sm focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-600 dark:placeholder:text-stone-300"
            />
        </div>
        <div v-else class="block text-sm text-gray-700 dark:text-white">
            <!-- read only -->
            <div class="inline" :class="textArea ? 'whitespace-pre-line' : ''">
                <span v-if="inputValue">{{ inputValue }}</span>
                <span v-else>—</span>
            </div>

            <win-clipboard v-if="showCopy" :text="inputValue" size="3" class="ml-2" />
        </div>

        <div v-if="hasSlot('right')" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <slot name="right"></slot>
        </div>

        <div v-if="hasSlot('rightclickable')" class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
            <slot name="rightclickable"></slot>
        </div>
    </div>
    <div v-if="hasError" class="text-sm italic text-red-500">{{ error }}</div>
</template>
