import { usePage } from '@inertiajs/vue3'

export default function translate(key) {
    const auth = usePage().props.auth;
    const isState = auth.user.roles.includes('state');

    const strings = {
        cbb: {
            authority: 'Authorization Request',
            authorities: 'Authorization Requests',
            approval: 'Approval Type',
            approvals: 'Approval Types',
        },
        state: {
            authority: 'Authority',
            authorities: 'Authorities',
            approval: 'Approval Path',
            approvals: 'Approval Paths',
        },
    };

    return strings[isState ? 'state' : 'cbb'][key];
}
