<script setup>
import { onUnmounted, ref } from 'vue';
import WinInput from '../Inputs/WinInput.vue';
import { router } from '@inertiajs/vue3';

const searchDocId = ref('');

const searchArms = () => {
    router.get('/documents', {
        search: searchDocId.value,
    });
};

onUnmounted(
    router.on('start', (event) => {
        // user navigating away from /documents, remove whatever they typed here
        if (event.detail.visit.url.pathname.toLowerCase() !== '/documents')
            searchDocId.value = '';
    })
);
</script>
<template>
    <win-input
        v-model="searchDocId"
        placeholder="Doc #"
        @keyup.enter="searchArms"
    ></win-input>
</template>
