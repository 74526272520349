<!--
Standard select box, no autocomplete.  Use <tw-auto-complete /> for that.
Maybe we should combine these two components?

    <tw-select
        label="EMR SSO"
        :select-items="[ {text:'Off',id: 0}, {text:'Available', id: 1}]"
        v-model="clientData.emr_sso"
        optional
        :disabled="clientData.is_demo || clientData.emr_type != 'luvo_emr'" />

Label Can Be a Slot:
    <tw-select ...>
        <div class="text-xl">Client Name</span>
    </tw-input>

Label Can be left-attached to the input with gray background:
    <tw-input ...
        leftText="Name" />

Space is reserved for the label even if it is empty (to align with other inputs).  Remove that space with hide-label:
    <tw-select
        hide-label
        .../>

-->
<script setup>
import { ref, watch, computed, onMounted, useSlots } from 'vue';
import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    ListboxLabel,
} from '@headlessui/vue';
import IconCheck from '~icons/heroicons-outline/check';
import IconSelector from '~icons/heroicons-outline/selector';
import IconCircleInfo from '~icons/fluent/info-20-regular';

const slots = useSlots();
const hasSlot = (name) => !!slots[name];

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: '',
    },
    label: String,
    error: String,
    leftText: String,
    selectItems: {
        type: Object,
        default: () => {
            return [];
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    optional: {
        type: Boolean,
        default: false,
    },
    // placeholder - if optional is true, you can override the first option text 'Select...'
    placeholder: {
        type: String,
        default: 'Select...',
    },
    hideLabel: {
        type: Boolean,
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    tooltip: {
        type: String,
        default: '',
    },
});

const inputValue = ref({ text: '', id: '' });

const selectItemsNormalized = computed(() => {
    if (props.selectItems.length > 0) {
        if (typeof props.selectItems[0] === 'string') {
            return props.selectItems.map((e) => {
                return { text: e, id: e };
            });
        }
    }

    // else just return the array of objects
    return props.selectItems;
});

const selectItemsMap = computed(() => {
    //console.log('selectItemsMap computed:',props.modelValue);
    //return cloneDeep(props.selectItems);

    if (props.optional) {
        return [
            { text: props.placeholder, id: '' },
            ...selectItemsNormalized.value,
        ];
    } else {
        return selectItemsNormalized.value;
    }
});

const setInputValue = () => {
    // modelValue could be string/number.  just check as string for now
    // could be null/undefined, which should be synonymous with ''
    const matchingRecord = selectItemsMap.value.find(
        (e) =>
            e.id.toString() ===
            (props.modelValue === null ? '' : props.modelValue.toString())
    );
    if (matchingRecord) inputValue.value = Object.assign({}, matchingRecord);
};

watch(
    () => props.modelValue,
    () => {
        // modelValue could be string/number.  just check as string for now
        // modelValue could be null/undefined, which should be synonymous with ''
        if (props.modelValue.toString() !== inputValue.value.id.toString()) {
            setInputValue();
        }
    }
);

watch(
    () => inputValue.value.id,
    () => {
        emit('update:modelValue', inputValue.value.id);
    }
);

onMounted(() => {
    setInputValue();

    // check to make sure, if optional=true, there isn't already an empty value.
    if (
        props.optional &&
        selectItemsNormalized.value.map((e) => e.id).includes('')
    ) {
        console.warn(
            `<tw-select> (${props.label}) select-items array contains an element with an empty id value while you have also set optional=true.  You can have one or the other, but not both.`
        );
    }
});

const hasError = computed(() => {
    return Boolean(props.error);
});
</script>

<template>
    <Listbox v-model="inputValue" as="div" :disabled="disabled">
        <ListboxLabel
            v-if="label || hasSlot('label')"
            class="inline-block cursor-default text-left text-sm leading-5 text-gray-700 dark:text-white"
            :class="required && !disabled ? 'font-bold' : 'font-medium'"
        >
            <template v-if="hasSlot('label')"
                ><slot name="label"></slot
            ></template>
            <template v-else>
                {{ label }}
                <span v-if="required && !disabled && !readOnly">*</span>
            </template>
            <div v-if="tooltip" class="inline print:hidden">
                <VTooltip class="inline cursor-pointer">
                    <sup><icon-circle-info class="inline h-4 w-4" /></sup>
                    <template #popper><div v-html="tooltip"></div></template>
                </VTooltip>
            </div>
        </ListboxLabel>
        <div v-else-if="!hideLabel" class="pb-4">
            <!-- no label but need to line-up input boxes on a row -->
        </div>

        <div class="relative">
            <div v-if="readOnly" class="text-sm">
                {{ inputValue.text || '—' }}
            </div>
            <div v-else class="flex">
                <!-- flex here to get the leftText to line up beside the dropdown -->

                <div
                    v-if="leftText"
                    class="inline-flex items-center whitespace-nowrap rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
                >
                    {{ leftText }}
                </div>

                <ListboxButton
                    :class="[
                        hasError
                            ? 'border-red-500'
                            : 'border-gray-300 dark:border-gray-500',
                        disabled ? 'cursor-not-allowed' : 'cursor-default',
                        leftText ? 'rounded-r-md' : 'rounded-md',
                    ]"
                    class="relative h-[2.375rem] w-full border bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none dark:bg-gray-600 sm:text-sm"
                >
                    <span class="block truncate">{{ inputValue.text }}</span>
                    <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                        <icon-selector
                            class="h-5 w-5 text-gray-400 dark:text-white"
                            aria-hidden="true"
                        />
                    </span>
                </ListboxButton>

                <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <ListboxOptions
                        class="absolute z-10 max-h-60 w-full overflow-auto rounded-md border border-gray-300 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-200 sm:text-sm"
                    >
                        <ListboxOption
                            v-for="selectItem in selectItemsMap"
                            :key="selectItem.id"
                            v-slot="{ active, selected }"
                            as="template"
                            :value="selectItem"
                        >
                            <li
                                :class="[
                                    'relative cursor-default select-none py-2 pl-3 pr-9',

                                    active
                                        ? 'bg-gray-300 text-black dark:bg-gray-600 dark:text-white'
                                        : selected ||
                                          selectItem.id === inputValue.id
                                        ? 'bg-gray-200 font-semibold dark:bg-gray-400 dark:text-white'
                                        : 'bg-white text-black dark:bg-gray-200',
                                ]"
                            >
                                <span class="block truncate">
                                    {{ selectItem.text }}
                                </span>

                                <span
                                    v-if="
                                        selected ||
                                        selectItem.id === inputValue.id
                                    "
                                    :class="[
                                        active ? 'text-white' : 'text-gray-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                    ]"
                                >
                                    <icon-check
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
            <div v-if="hasError" class="text-sm italic text-red-500">
                {{ error }}
            </div>
        </div>
    </Listbox>
</template>
