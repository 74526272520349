<script setup>
/*
We have two "loading" ui elements:
    - This Loading.vue component which is the app "spinner" (no indication of progress)
    - The default Inertia.js "InertiaProgress" in app.js which is at the top and starts at zero and moves to the right
*/
import { onUnmounted, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import IdleTimer from './Layout/IdleTimer.vue';
//import random from 'lodash/random';

const showProgress = ref(false);
let waitingOnDelay = false;
const delay = 250; //ms delay to wait until kicking in a spinner so we don't "flash" the spinner on quick responses
//const animationOptions = ['animate-spin', 'animate-ping', 'animate-pulse', 'animate-bounce'];
//const animateClass = ref('animate-ping');
const start = () => {
    //animateClass.value = animationOptions[random(0, animationOptions.length - 1)];
    waitingOnDelay = true;
    setTimeout(() => {
        if (waitingOnDelay) showProgress.value = true;
    }, delay);
};

const stop = () => {
    waitingOnDelay = false;
    showProgress.value = false;
};

onUnmounted(
    router.on('start', () => start())
);

onUnmounted(
    router.on('finish', (event) => {
        if (event.detail.visit.completed || event.detail.visit.cancelled) stop();
    })
);

const image = new Image();
image.src = '/android-chrome-192x192.png';
</script>

<template>
    <div v-if="showProgress" class="print:hidden">
        <!--throw down a full screen opaque gray overlay-->
        <div class="fixed right-0 top-0 z-40 h-screen w-screen bg-gray-50 opacity-50"></div>

        <!--put the spinner in w/o opacity on top of the gray overlay-->
        <div class="fixed right-0 top-0 z-50 flex h-screen w-screen items-center justify-center">
            <!--don't worry about this image not caching during dev as devtools being open prevents cache!-->
            <img class="w-9 animate-ping opacity-70" :src="'/android-chrome-192x192.png'" alt="Loading Spinner" />
        </div>
    </div>
    <idle-timer />
</template>

<!--<style>
.ripple {
    display: block;
    border-radius: 100%;
    borderx: 1px rgba(255, 255, 0, 0.5) solid;
    animation: pulse 1s infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 0, 0.8);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}
</style>-->
