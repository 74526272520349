<script setup>
import { onUnmounted, ref, watch } from 'vue';
import { useTimeoutPoll } from '@vueuse/core';
import { humanizeSeconds } from '../../Hooks/useTime';
import { router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3'

import WinDialog from '../Dialogs/WinDialog.vue';
import WinButton from '../WinButton.vue';

const oneSecond = 1000;
const oneMinute = oneSecond * 60;

const idleMax = oneMinute * 600; // 10 hour
const idleWarning = idleMax - oneMinute * 5; //5 minutes of warning
const idleCheckInterval = oneSecond * 15;
const secondsLeft = ref(0);
const showIdleDialog = ref(false);
const lastInteraction = ref(Date.now());

const page = usePage();

let watcher;

const idleReset = () => {
    //console.log('inertia start, reset last interaction!');
    showIdleDialog.value = false;
    lastInteraction.value = Date.now(); //reset the idle timer on auto-logoff
};

onUnmounted(
    router.on('start', () => idleReset())
);

const updateIdleAmount = () => {
    const idleAmount = Date.now() - lastInteraction.value;
    secondsLeft.value = Math.round((idleMax - idleAmount) / 1000);
    if (secondsLeft.value <= 0) {
        showIdleDialog.value = false;
        logout();
    }
};

const { pause, resume } = useTimeoutPoll(updateIdleAmount, 1000);

watch(
    () => showIdleDialog.value,
    (newValue) => {
        if (newValue) resume();
        else pause();
    }
);

if (page.component.substring(0, 4).toLowerCase() !== 'auth') {
    // console.log('setInterval');
    watcher = setInterval(() => {
        const idleAmount = Date.now() - lastInteraction.value;

        // console.log('watching for idle', idleAmount);
        // console.log('idleWarning threshold:', idleWarning);
        // console.log('idleMax threshold:', idleMax);

        if (idleAmount > idleMax) logout();
        else if (idleAmount > idleWarning) showIdleDialog.value = true;
    }, idleCheckInterval);
}

const logout = () => {
    if (watcher) clearInterval(watcher);
    router.post('/logout');
};

onUnmounted(() => {
    clearInterval(watcher);
});

const refreshSession = () => {
    //reload whatever page we are on
    router.get(route(route().current(), route().params));
};
</script>

<template>
    <win-dialog v-model="showIdleDialog" size="sm">
        <template #header>Still There?</template>
        <template #default>
            <div class="py-4">
                Your session is about to expire due to inactivity.
            </div>
        </template>
        <template #footer>
            <div class="flex justify-between">
                <win-button color-style="primary" @click="refreshSession"
                    >Stay Logged In</win-button
                >
                <win-button color-style="danger" @click="logout"
                    >Log Off Now (
                    <div class="inline font-mono">
                        {{ secondsLeft > 0 ? humanizeSeconds(secondsLeft) : 0 }}
                    </div>
                    )</win-button
                >
            </div>
        </template>
    </win-dialog>
</template>
