<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3'
import { router } from '@inertiajs/vue3';
import { useInfiniteScroll } from '@vueuse/core'

import Pagination from './../Shared/Pagination.vue';
import DocumentFilter from './Documents/DocumentFilter.vue';

import IconCaretUp from '~icons/bxs/up-arrow-alt';
import IconCaretDown from '~icons/bxs/down-arrow-alt';
import IconCancel from '~icons/heroicons-outline/x';
import IconFilter from '~icons/carbon/filter';

import IconOverdue from '~icons/fa6-solid/triangle-exclamation';
import IconDueSoon from '~icons/fa6-solid/circle-info';

import WinExport from './WinExport.vue';
import WinInput from "./Inputs/WinInput.vue";

import debounce from 'lodash/debounce';
import Uniq from 'lodash/uniq';
import dayjs from 'dayjs';

const emit = defineEmits(['next']);
// directly grab shared data from controller, even though it's intended for parent component
//  https://inertiajs.com/shared-data
const filters = computed(() => usePage().props?.filters);
const documentFilters = reactive({
    status: [],
    timeSensitive: false,
    onlyMe: false,
    mounted: false, // just tracking if we've mounted and reset filters based on querystring below in onMounted()
});

let usingInfiniteScroll = false; //track if infiniteScroll is on or off.  It's off now, but will check down below by looking at the prop.

onMounted(() => {
    if (props.showDocumentFilters) {
        if (filters.value.documentFilters) {
            documentFilters.onlyMe = filters.value.documentFilters.onlyMe === 'true';
            documentFilters.timeSensitive = filters.value.documentFilters.timeSensitive === 'true';

            documentFilters.status = Uniq(filters.value.documentFilters.status);
        }
        documentFilters.mounted = true; //keeping track that we've mounted and loaded querystring before <document-filter /> below
    }
});
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    searchable: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array,
        default: () => [],
        description: 'Required: Array of objects (usually, from Inertia, this is prop.data)',
    },
    striped: {
        type: Boolean,
        default: false,
        description: 'Optional, stripe alternating rows',
    },
    showHeader: {
        //optional
        type: Boolean,
        default: false,
        description: 'Optional, show header row with column names',
    },
    tableFixed: {
        //if true, set
        type: Boolean,
        default: false,
        description:
            'Optional, fix column widths.  If true, pass a tailwind width class for each column in the class property (class: "w-1/6") or it will default to each column being same width',
    },
    fields: {
        type: Array,
        default: () => [],
        description:
            'Optional: Override columns by passing in array of keys (column ids) or array of objects { key: "", label: "", sortable: true, class: "" }',
        // examples:
        // ['name','email']
        // or
        // [{ key: 'id', label: 'ID', class: 'w-1/12'},{ key: 'name', label: 'Employee Name', class:'w-1/2' },{ key: 'email', label: 'Email', class:'w-5/12' },]
    },
    paginationLinks: {
        type: Array,
        default: () => [],
        description: 'Optional.  Pass in prop.links from Inertia to show pagination below table.',
    },
    filters: Object,
    pagination: Object,
    rowLink: {
        type: Boolean,
        default: false,
        description: 'Optional.  If true, will add a link to the row.  If false, will not add a link to the row.',
    },
    showDocumentFilters: {
        type: Boolean,
        default: false,
        description:
            'Optional.  If true, will show the document filters below the table.  If false, will not show the document filters.',
    },
    showExport: {
        type: Boolean,
        default: false,
        description:
            'Optional.  If true, will show the export button below the table.  If false, will not show the export button.',
    },
    infiniteScroll: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    showColumnFilters: {
        type: Boolean,
        default: false,
    }
});

const applyDocumentFilter = (filter) => {
    documentFilters.status = filter.status;
    documentFilters.timeSensitive = filter.timeSensitive;
    documentFilters.onlyMe = filter.onlyMe;

    router.get(
        '/documents',
        {
            documentFilters,
            search: search.value,
            sort: filters.value.sort,
            order: filters.value.order,
        },
        {
            preserveState: true,
            replace: true,
        }
    );
};

const search = ref(filters.value?.search || '');

// if server gives us column_search, use it, otherwise, create an array of empty strings
const columnSearch = reactive(Array.isArray(usePage().props?.column_search) ? {} : usePage().props?.column_search);//usePage().props?.column_search);

// const rowClick = (row) => {
//     if (props.rowLink && row.link) {
//         Inertia.visit(row.link);
//     }
// };

const doSearch = () => {
    console.log('doSearch', columns.value);
    tableContainer.value.scrollTop = 0;
    router.get(
        route(route().current()),
        {
            search: search.value,
            columnSearch,
        },
        {
            preserveState: true,
            replace: true,
        }
    );
};

const debouncedSearch = debounce(doSearch, 750);

const cancelSearch = () => {
    search.value = '';
    setTimeout(debouncedSearch.flush, 100);
};

const immediateSearch = () => {
    debouncedSearch.flush();
};

watch(search, () => {
    console.log('watchSearch');
    debouncedSearch();
});

watch(columnSearch, () => {
    console.log('columnSearch changed!');
    debouncedSearch();
}, { deep: true });

const rows = computed(() => [...props.items]);

// watch rows and if it grows past perPage (200), turn on infinite scroll
watch(rows, (newVal, oldVal) => {
    console.log('rows changed', newVal.length, oldVal.length);
    if (newVal.length>=props.pagination?.per_page) {
        enableInfiniteScroll();
    }
});
const columns = computed(() => {
    if (Array.isArray(props.fields) && props.fields.length > 0) {
        // array of keys (column ids) or objects { key: '', label: '', sortable: boolean (false) }
        let defaultWidth;

        if (props.tableFixed) {
            defaultWidth = props.fields.length > 1 && props.fields.length <= 12 ? 'w-1/' + props.fields.length : '';
        }

        if (typeof props.fields[0] === 'string') {
            return props.fields.map((e) => {
                return { key: e, label: e, class: defaultWidth };
            });
        }
        if (typeof props.fields[0] === 'object') {
            return [...props.fields];
            //return props.fields.map(e=>e.key);
        }
        return [];
    } else if (rows.value.length > 0) {
        //fallback to default by looking at the first row of data and generating column names
        let defaultWidth;

        if (props.tableFixed) {
            // just default to each column getting same width
            const fieldCount = Object.keys(rows.value[0]).length;
            defaultWidth = fieldCount > 1 && fieldCount <= 12 ? 'w-1/' + fieldCount : '';
        }

        return Object.keys(rows.value[0]).map((e) => {
            return { key: e, label: e, class: defaultWidth };
        });
    } else {
        return ['fail'];
    }
});

const hasIcon = (iconName = '', text = '') => {
    //  check text for icon tag ~~icon:iconName~~ and return true or false
    return text?.toString().includes('~~icon:' + iconName + '~~');
}
const filterIconTag = (text = '') => {
    //clear out icon tags
    return text?.toString().replace(/~~icon:(.*?)~~/g, '');
}

const tableContainer = ref(null);

// this is the function to try to enable infinite scroll.
// It's called in watch(rows) above and also when the page is mounted below checking props.infiniteScroll
// This way we can check if it's necessary and only enable it if it's needed.
// There was a bug when the page was mounted and rows were < 200 (say, someone had a filter set on the docs page)
// and it kept trying to load more docs (which the js code has to bail).  Still, it was checking every tick() and seemed bad.
const enableInfiniteScroll = () => {
    console.log('perPage', props.pagination?.per_page);
    if (!usingInfiniteScroll && props.infiniteScroll && rows.value.length>=props.pagination?.per_page) {
        console.log('enabling infinite scroll');
        usingInfiniteScroll = true;

        useInfiniteScroll(
            tableContainer,
            () => {
                // load more
                console.log('load more', props.items.length);

                // for some reason this gets invoked multiple times in a row when user gets to bottom of element,
                // so we need to check if we're already loading and bail if we are.
                if (props.loading) {
                    console.log('already loading, skipping emit');
                    return;
                }
                emit('next', search.value, columnSearch);
            },
            { distance: 400 }
        )
    } else {
        console.log('not enabling infinite scroll', usingInfiniteScroll, props.infiniteScroll, rows.value.length);
    }
}

if (props.infiniteScroll) {
    // try to enable infinite scroll on mount if it makes sense to (it'll check how many rows there are).
    enableInfiniteScroll();
}
// const isUrl = (string) => {
//     let url;
//
//     try {
//         url = new URL(string);
//     } catch (_) {
//         return false;
//     }
//
//     return url.protocol === 'http:' || url.protocol === 'https:';
// };
</script>

<template>
    <div class="flex items-end justify-between pb-2">
        <div class="">
            <div v-if="filters?.search" class="text-lg font-semibold">Searching For: {{ filters.search }}</div>
            <div class="hidden sm:inline">
                <template v-if="infiniteScroll">
                    Showing {{ title }} {{ pagination?.from }}-{{ items.length }} of {{ pagination?.total }} total.
                </template>
                <template v-else>
                    Showing {{ title }} {{ pagination?.from }}-{{ pagination?.to }} of {{ pagination?.total }} total.
                </template>
            </div>
            <div class="inline sm:hidden">
                <template v-if="infiniteScroll">
                    Showing {{ title }} {{ pagination?.from }}-{{ items.length }} of {{ pagination?.total }} total.
                </template>
                <template v-else>
                    Showing {{ pagination?.from }}-{{ pagination?.to }} of {{ pagination?.total }}
                </template>
            </div>
        </div>
        <div>
            <div v-if="showDocumentFilters" class="inline-flex pr-5 pt-3 align-top">
                <document-filter
                    v-if="documentFilters.mounted"
                    :initial-filter="documentFilters"
                    @apply-filter="applyDocumentFilter"
                />
            </div>
            <div class="inline-flex">
                <win-input
                    v-if="searchable"
                    v-model="search"
                    :placeholder="`Search ${title}...`"
                    @keyup.enter="immediateSearch"
                >
                    <template #rightclickable>
                        <icon-cancel
                            class="h-3 w-3 text-gray-400 hover:text-red-500"
                            aria-hidden="true"
                            @click="cancelSearch"
                        />
                    </template>
                </win-input>
            </div>
        </div>
    </div>

    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div ref="tableContainer" class="overflow-y-scroll border-b border-gray-200 shadow dark:border-gray-900 sm:rounded-lg max-h-[75vh]">
                    <table
                        :class="{
                                'table-fixed': tableFixed,
                                'opacity-50': loading,
                            }"
                        class="sticky w-full divide-y divide-gray-200 dark:divide-gray-900"
                    >
                        <thead class="bg-neutral-200 dark:bg-gray-800 sticky top-0">
                            <tr v-if="showHeader">
                                <!-- eslint-disable-next-line vue/require-v-for-key -->
                                <th
                                    v-for="(column, i) in columns"
                                    scope="col"
                                    :class="tableFixed ? column.class : ''"
                                    class="text-clip whitespace-nowrap px-6 py-2 text-left text-xs font-bold uppercase tracking-wider text-gray-500 dark:text-white"
                                >
                                    <inertia-link
                                        v-if="column.sortable"
                                        :href="$page.url"
                                        :data="{
                                            sort: column.altSortKey ?? column.key,
                                            order: filters.order === 'asc' ? 'desc' : 'asc',
                                        }"
                                    >
                                        <div class="inline-block align-middle">
                                            {{ column.label }}
                                        </div>
                                        <div class="inline-block align-middle">
                                            <icon-filter
                                                v-if="columnSearch[column.key]"
                                                class="inline h-4 w-4 text-gray-600 dark:text-gray-300 ml-1"
                                            />
                                            <icon-caret-up
                                                v-if="
                                                    filters.sort === (column.altSortKey || column.key) &&
                                                    filters.order === 'asc'
                                                "
                                                class="inline h-4 w-4 text-gray-600 dark:text-gray-300"
                                            />
                                            <icon-caret-down
                                                v-if="
                                                    filters.sort === (column.altSortKey || column.key) &&
                                                    filters.order === 'desc'
                                                "
                                                class="inline h-4 w-4 text-gray-600 dark:text-gray-300"
                                            />
                                        </div>
                                    </inertia-link>
                                    <span v-else>{{ column.label }}</span>
                                    <!-- column search -->
                                    <div v-if="showColumnFilters">
                                        <div v-if="!column.hideSearch" >
    <!--                                        <win-date v-if="column.key==='created_at'" v-model="columnSearch[i]" class="h-6 p-0.5 rounded-none text-xs font-light text-gray-900" />-->
                                            <win-input v-model="columnSearch[column.key]" @keyup.enter="immediateSearch" class="h-6 p-0.5 rounded-none text-xs font-light text-gray-900" />
                                        </div>
                                        <div v-else class="h-7"></div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <!-- eslint-disable-next-line vue/require-v-for-key -->
                            <tr
                                v-for="(row, rowIdx) in rows"
                                :class="[
                                    !striped || rowIdx % 2 === 0
                                        ? 'bg-white dark:bg-gray-500'
                                        : 'bg-neutral-100 dark:bg-gray-600',
                                ]"
                            >
                                <!-- eslint-disable-next-line vue/require-v-for-key -->
                                <td
                                    v-for="column in columns"
                                    class="truncate whitespace-nowrap px-6 py-1 text-sm font-medium dark:text-white"
                                    :class="tableFixed ? column.class : ''"
                                >
                                    <span v-if="row.link">
                                        <inertia-link :href="row.link">
                                            <span v-if="column.type === 'boolean'">
                                                {{ row[column.key] ? 'Yes' : 'No' }}
                                            </span>
                                            <span v-else>
                                                {{ filterIconTag(row[column.key]) }}
                                                <icon-due-soon v-if="hasIcon('soon',row[column.key])" v-tooltip="'Pending Final' + dayjs(row['final_date']).format(' MMM D')" class="inline text-xs text-rose-800 ml-1" />
                                                <icon-overdue v-else-if="hasIcon('overdue',row[column.key])" v-tooltip="'Overdue' + dayjs(row['final_date']).format(' MMM D, YYYY')" class="inline text-xs text-rose-800 ml-1" />
                                            </span>
                                        </inertia-link>
                                    </span>
                                    <span v-else-if="column.link && row[column.key]">
                                        <a
                                            v-if="column.linkExternal"
                                            :href="row[column.key]"
                                            class="text-sky-700 underline"
                                        >
                                            {{ column.linkText || row[column.linkTextField] || 'Link' }}
                                        </a>
                                        <inertia-link v-else :href="row[column.key]">
                                            {{ column.linkText || column.linkTextField || 'Link' }}
                                        </inertia-link>
                                    </span>
                                    <span v-else>
                                        <span v-if="column.type === 'boolean'">
                                            {{ row[column.key] ? 'Yes' : 'No' }}
                                        </span>
                                        <span v-else>{{ row[column.key] }}</span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <win-export v-if="showExport" class="float-right mt-2" />

    <Pagination v-if="!infiniteScroll && paginationLinks.length > 0" :links="paginationLinks" class="mt-3" />


</template>
