<!--
Basic usage:

    <tw-checkbox
        label="Demo Mode Enabled"
        name="is_demo"
        v-model="clientData.is_demo" />

    You can also supply the label as the default slot content: <tw-checkbox>Label</tw-checkbox>

Vuex Binding Errors:

    If you previously were binding a vuex state via v-model with @change, you will need to disable the two-way binding so vuex does not error:

    <tw-checkbox
        label="Demo Mode Enabled"
        name="is_demo"
        :model-value="clientData.is_demo" <-- Data flows to tw-checkbox, but not back to vuex
        @change="updateClientData" <-- Here is where you call the vuex mutation
        />

Multiple checkboxes:

    Mimics Vue for multiple (grouped) <input> checkboxes
        you can pass an array as v-model then the actual value of the checkbox passed in as :value property.
        v-model will emit the updated array (with the value if checked, without if not)
        It also emits @change event if you aren't watching the v-model

    <tw-checkbox
        label="Permissions"
        name="view_emr'"
        v-model="['view_emr','view lighthouse']"
        value="view_emr"
        @change="handleChange" />
-->
<script setup>
import { ref, watch, onMounted } from 'vue';
import isEqual from 'lodash/isEqual.js';

const emit = defineEmits(['update:modelValue', 'change']);

const props = defineProps({
    label: String, // use this OR slot named label
    labelHelp: String, // you can use this if using label attribute (not slot)
    name: {
        type: String,
        required: true,
    },
    // modelValue is a Bool/Number/String in most simple cases (isSingle.value=true)
    // Can be an array to mimic vue on multiple (grouped) <input> checkboxes (isSingle.value=false)
    modelValue: {
        type: [Array, Boolean, Number, String],
        default: false,
        required: true,
    },
    // Value is optional and should be used if you are passing an array as modelValue
    value: {
        type: [Boolean, Number, String],
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    trueValue: {
        type: [Boolean, Number, String],
        default: true,
    },
    falseValue: {
        type: [Boolean, Number, String],
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
});

const isSingle = ref(true); // whether single checkbox v-model is basically true/false.  Otherwise, v-model is an array.
const isChecked = ref(null); // whether checkbox is checked or not.  Set onMounted()

onMounted(() => {
    // determine if single or part of multiple checkboxes
    if (Array.isArray(props.modelValue)) isSingle.value = false;

    // initialize isChecked
    setIsChecked();
});

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue !== null) setIsChecked();
    }
);

const setIsChecked = () => {
    if (isSingle.value) {
        isChecked.value = Boolean(props.modelValue);
    } else {
        isChecked.value = props.modelValue.includes(props.value);
    }
};

// compare v-model arrays if multiple checkboxes.  Don't care about order in array.
// const arraysEqual = (a, b) => {
//     return isEqual(a.sort(), b.sort());
// };

/*
Watch isChecked

If change in checkbox then handle the emits.
Be careful to not emit if we are just setting up isChecked on mounted.
 */
watch(isChecked, () => {
    if (!isSingle.value) {
        // if v-model/modelValue is array

        // remove any possible duplicates from modelValue, so we can compare before & after values more easily
        const currentModelValue = Array.from(new Set([...props.modelValue]));

        let newModelValue;
        if (isChecked.value) {
            //push onto array if not already there
            let s = new Set(currentModelValue);
            s.add(props.value);
            newModelValue = Array.from(s);
        } else {
            // remove from array if already there
            newModelValue = currentModelValue.filter((e) => e !== props.value);
        }

        const thereWasAnActualChange = !isEqual(
            newModelValue.sort(),
            currentModelValue.sort()
        );

        // only emit if there was a change.
        // This happens on mount when we are checking the box programmatically but should not emit a change event.
        if (thereWasAnActualChange) {
            emit('update:modelValue', newModelValue); // not sure if I should be emitting this or not. Seems to throw error on vuex bindings that didn't error in vue2.
            emit('change', isChecked.value ? props.value : null);
        }
    } else {
        // standard/basic single checkbox, just emit true/false
        const thereWasAnActualChange =
            (isChecked.value ? props.trueValue : props.falseValue) !==
            props.modelValue;

        if (thereWasAnActualChange) {
            emit('update:modelValue', isChecked.value);
            emit('change', null);
        }
    }
});
</script>

<template>
    <div class="flex" @click.stop="">
        <div class="flex-1">
            <div class="inline h-5">
                <input
                    :id="name"
                    aria-describedby="comments-description"
                    :name="name"
                    :checked="isChecked"
                    type="checkbox"
                    :true-value="trueValue"
                    :false-value="falseValue"
                    :disabled="disabled || readOnly"
                    class="h-4 w-4 rounded border-gray-300"
                    :class="
                        disabled
                            ? 'cursor-not-allowed text-gray-400'
                            : 'text-gray-600 focus:ring-gray-600 focus:ring-opacity-25'
                    "
                    @change="isChecked = $event.target.checked"
                />
            </div>
            <div class="ml-1.5 inline text-sm">
                <label
                    :for="name"
                    class="text-sm"
                    :class="
                        disabled
                            ? 'text-gray-400'
                            : readOnly
                            ? 'text-gray-700 dark:text-white'
                            : 'cursor-pointer text-gray-700 dark:text-white'
                    "
                >
                    <template v-if="$slots.default">
                        <slot></slot>
                    </template>
                    <template v-else>{{ label }}</template>
                </label>
                <p
                    v-if="labelHelp"
                    id="comments-description"
                    class="text-gray-500"
                >
                    {{ labelHelp }}
                </p>
            </div>
        </div>
    </div>
</template>
