import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

// export function formatDate(dt) {
//     dayjs(dt).format('YYYY-MM-DD');
// }

// display how many hours ago
export function timeAgo(dt) {
    // IMPORTANT - this only works for PAST TENSE
    // Don't use for "in 5 days" because the language overrides don't handle that.  Create a different export function for that.
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
    dayjs.updateLocale('en', {
        relativeTime: {
            future: '%s',
            past: '%s',
            s: 'a few seconds ago',
            m: 'a minute ago',
            mm: '%d minutes ago',
            h: 'an hour ago',
            hh: '%d hours ago',
            d: 'yesterday',
            dd: '%d days ago',
            M: 'a month ago',
            MM: '%d months ago',
            y: 'a year ago',
            yy: '%d years ago',
        },
    });
    return dayjs(dt).fromNow();
}

/**
 * @param {string} dt
 */
export function formatRelative(dt) {
    dayjs.extend(calendar);
    dayjs.extend(updateLocale);
    dayjs.updateLocale('en', {
        calendar: {
            lastDay: '[yesterday at] h:mm A',
            sameDay: '[today at] h:mm A',
            nextDay: '[tomorrow at] h:mm A',
            lastWeek: '[last] dddd [at] h:mm A',
            nextWeek: 'dddd [at] h:mm A',
            sameElse: 'on MMM D, YYYY',
        },
    });
    return dayjs(dt).calendar();
}

export const humanizeSeconds = (seconds) => {
    return new Date(1000 * seconds)
        .toISOString()
        .substring(11, 19)
        .replace(/^[0:]+/, '');
};

export const toUtcDate = (dt) => {
    dayjs.extend(utc);
    return dayjs(dt).utc().format('M/D/YYYY');
};
