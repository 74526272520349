<script setup>
import { ref, onMounted, watch } from 'vue';

import TwDropdown from '../TwDropdown.vue';
import TwDropdownItem from '../TwDropdownItem.vue';
import WinCheckbox from '../Inputs/WinCheckbox.vue';

import IconFilter from '~icons/carbon/filter-edit';
import IconStopwatch from '~icons/fa6-solid/stopwatch';
import IconUserCheck from '~icons/fa6-solid/user-check';
import IconCheck from '~icons/fa6-solid/check';
import IconXMark from '~icons/fa6-solid/xmark';

const emit = defineEmits(['apply-filter']);
const props = defineProps({
    initialFilter: Object,
});

const hideDropdown = ref(true);

const searchStatus = ref([...props.initialFilter.status]);
const searchTimeSensitive = ref(props.initialFilter.timeSensitive);
const searchOnlyMe = ref(props.initialFilter.onlyMe);

// "static" versions only change on emit so we know when to update the UI with the filter list (with icons)
const staticSearchStatus = ref([]);
const staticSearchTimeSensitive = ref(false);
const staticSearchOnlyMe = ref(false);

const filterDropdown = ref();

const applyFilters = () => {
    emit('apply-filter', {
        status: searchStatus.value,
        timeSensitive: searchTimeSensitive.value,
        onlyMe: searchOnlyMe.value,
    });
    updateStaticFilters();
};

// const hasFilters = computed(() => {
//     return (
//         staticSearchStatus.value.length > 0 ||
//         staticSearchTimeSensitive.value ||
//         staticSearchOnlyMe.value
//     );
// });

// const clearFilters = () => {
//     searchStatus.value = [];
//     searchTimeSensitive.value = false;
//     searchOnlyMe.value = false;
//     updateStaticFilters();
//     applyFilters();
// };

const updateStaticFilters = () => {
    staticSearchStatus.value = searchStatus.value;
    staticSearchTimeSensitive.value = searchTimeSensitive.value;
    staticSearchOnlyMe.value = searchOnlyMe.value;
};

onMounted(() => {
    updateStaticFilters();
});

// const clearAllFilters = () => {
//     searchStatus.value = [];
//     searchTimeSensitive.value = false;
//     searchOnlyMe.value = false;
//     hideDropdownAndApply();
// };

const showDropdown = () => {
    hideDropdown.value = false;
    filterDropdown.value.initiateDropdown();
};
// const hideDropdownAndApply = () => {
//     hideDropdown.value = true;
//     applyFilters();
// };

const display = (status) => {
    switch (status) {
        case 'draft':
            return 'Draft';
        case 'pending_final':
        case 'final':
            return 'Approved';
        case 'pending_usda':
            return 'Pending USDA';
        case 'pending_cbb':
            return 'Pending CBB';
        case 'rejected':
            return 'Rejected';
        default:
            return status;
    }
};

watch([searchStatus, searchTimeSensitive, searchOnlyMe], () => {
    applyFilters();
});
</script>

<template>
    <div>
        <div class="hidden pr-4 text-sm xl:inline">
            <div class="inline px-1">
                <span v-for="status in staticSearchStatus" class="pr-2">
                    <icon-check class="inline h-3 w-3" />
                    {{ display(status) }}
                </span>
            </div>
            <div v-if="staticSearchTimeSensitive" class="inline px-1">
                <icon-stopwatch class="inline h-3 w-3" />
                Time Sensitive
            </div>
            <div v-if="staticSearchOnlyMe" class="inline px-1">
                <icon-user-check class="inline h-3 w-3" />
                Only Me
            </div>

            <!--            <div
                v-if="hasFilters"
                class="mr-3 inline cursor-pointer rounded-md bg-gray-400 px-1.5 pb-1.5 pt-1 text-white"
                @click="clearFilters"
                title="Clear Filters"
            >
                <icon-x-mark class="inline h-5 w-5" />
            </div>-->
        </div>

        <icon-filter
            class="-mr-2 -mt-0.5 inline h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-600 dark:text-white"
            @click="showDropdown"
        />

        <tw-dropdown ref="filterDropdown" class="normal-case" hide-icon>
            <div v-if="!hideDropdown">
                <div class="absolute top-1.5 right-1.5 cursor-pointer" title="close" @click="hideDropdown = true">
                    <icon-x-mark class="h-3.5 w-3.5 text-gray-500 hover:text-gray-900" />
                </div>
                <tw-dropdown-item>
                    <b>Status</b>
                    <win-checkbox v-model="searchStatus" name="searchStatusDraft" label="Draft" value="draft" />
                    <win-checkbox
                        v-model="searchStatus"
                        name="searchStatusRejected"
                        label="Rejected"
                        value="rejected"
                    />
                    <win-checkbox
                        v-model="searchStatus"
                        name="searchStatusPendingCbb"
                        label="Pending CBB"
                        value="pending_cbb"
                    />
                    <win-checkbox
                        v-model="searchStatus"
                        name="searchStatusPendingUsda"
                        label="Pending USDA"
                        value="pending_usda"
                    />
                    <win-checkbox
                        v-model="searchStatus"
                        name="searchStatusPendingFinal"
                        label="Approved"
                        value="pending_final"
                    />
                    <!--                    <win-checkbox
                        v-model="searchStatus"
                        name="searchStatusFinal"
                        label="Final"
                        value="final"
                    />-->
                </tw-dropdown-item>
                <tw-dropdown-item>
                    <b>Time Sensitive</b>
                    <win-checkbox v-model="searchTimeSensitive" name="searchTimeSensitive" label="Yes" />
                </tw-dropdown-item>
                <tw-dropdown-item>
                    <b>Misc</b>
                    <win-checkbox v-model="searchOnlyMe" name="searchOnlyMe" label="Only Me" />
                </tw-dropdown-item>

                <!--                <div class="flex w-full justify-between border-t pt-2">
                    <win-button
                        title="Clear Filters"
                        class="ml-2"
                        size="3xs"
                        color-style="danger"
                        icon="x"
                        @click="clearAllFilters"
                    />
                    <win-button
                        title="Apply Filters"
                        class="mr-2"
                        size="3xs"
                        color-style="success"
                        icon="check"
                        @click="hideDropdownAndApply"
                    />
                </div>-->
            </div>
        </tw-dropdown>
    </div>
</template>
