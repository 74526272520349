<script setup>
import { ref, onMounted } from 'vue';
import IconCheckCircle from '~icons/heroicons-outline/check-circle';
import IconExclamation from '~icons/heroicons-outline/exclamation';
import IconX from '~icons/heroicons-outline/x';

// const colors = {
//     default: {
//         background: "bg-emerald-600"
//     },
//     error: {
//         background: "bg-red-500"
//     }
// };

const display = ref(false);
//const color = reactive({});

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    message: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'default', // 'default, error'
    },
    timeout: {
        type: Number,
        default: 4000, // ms until it goes away.  Pass 0 to make user close it by clicking "x".
    },
    place: {
        type: Number,
        default: 0,
    },
    options: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(['hide']);

const close = () => {
    //console.log('closing toast ' + props.place);
    display.value = false;
    emit('hide');
};

const displayToast = () => {
    display.value = true;

    if (props.timeout > 0) {
        //console.log('setting timeout', props.timeout);
        setTimeout(() => {
            close();
        }, props.timeout);
    }
    // console.log("type:", props.type);
    // switch (props.type) {
    //     case "error":
    //         color.background = colors.error.background;
    //         break;
    //     default:
    //         color.background = colors.default.background;
    // }
};

onMounted(() => {
    //if (props.modelValue) displayToast();
    displayToast();
});
</script>

<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-x-0 z-10 flex items-end px-4 py-6 sm:items-start sm:p-6"
        :style="'bottom: ' + place * 65 + 'px;'"
    >
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <div
                v-if="display && (title || message)"
                class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-2 dark:bg-gray-600"
                :class="{
                    'bg-green-200': type === 'default',
                    'ring-green-500': type === 'default',
                    'bg-red-200': type === 'error',
                    'ring-red-500': type === 'error',
                }"
            >
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <icon-exclamation v-if="type === 'error'" class="h-6 w-6 text-red-400" aria-hidden="true" />
                            <icon-check-circle v-else class="h-6 w-6 text-green-600" aria-hidden="true" />
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p
                                v-if="title"
                                class="dark:text-md text-sm font-medium text-gray-900 dark:font-normal dark:text-white"
                            >
                                {{ title }}
                            </p>
                            <p
                                v-if="message"
                                :class="{
                                    'mt-1': Boolean(title),
                                    'text-green-700': type === 'default',
                                    'text-red-700': type === 'error',
                                }"
                                class="text-sm dark:text-white"
                            >
                                {{ message }}
                            </p>
                        </div>
                        <div class="ml-4 flex flex-shrink-0">
                            <button
                                class="inline-flex rounded-md focus:outline-none focus:ring focus:ring-offset-2 dark:bg-gray-600 dark:hover:text-white"
                                :class="{
                                    'bg-green-200 text-green-400 hover:bg-green-500 focus:ring-green-700': type === 'default',
                                    'bg-red-200 text-red-400 hover:bg-red-500 hover:text-red-100 focus:ring-red-700': type === 'error',
                                }"
                                @click="close"
                            >
                                <span class="sr-only">Close</span>
                                <icon-x class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
