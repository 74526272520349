import '@fontsource/inter/400.css';
import 'floating-vue/dist/style.css';
// import '@fontsource/inter/files/inter-all-400-normal.woff';
// import '@fontsource/inter/files/inter-latin-400-normal.woff2';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { registerGlobalComponents } from './global-components';
import ArmsLayout from './Shared/ArmsLayout.vue';
import HoneybadgerVue from '@honeybadger-io/vue';
import mitt from 'mitt';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

const honeyBadgerConfig = {
    apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
    environment: import.meta.env.MODE,
    revision: '__ARMS_GIT_LONG__', //this is being injected by vite - see vite.config.js
};
//console.log(honeyBadgerConfig);

let asyncViews = () => {
    return import.meta.glob('./Pages/**/*.vue');
};

createInertiaApp({
    resolve: async (name) => {
        console.log('name', `./Pages/${name}.vue`);
        const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'));
        // if name value is not found in array
        if (!['Auth/Login', 'Auth/Reset', 'Auth/Forgot'].includes(name)) {
            page.default.layout ??= ArmsLayout;
        }
        return page;
    },
    setup({el, App, props, plugin}) {
        const emitter = mitt();
        const app = createApp({
            render: () => h(App, props),
        });
        registerGlobalComponents(app);
        app.provide('emitter', emitter);
        app.use(plugin);
        app.use(HoneybadgerVue, honeyBadgerConfig);
        app.mixin({
            methods: {
                route,
            },
        });
        app.mount(el);
        return app;
    },
    // resolve: async (name) => {
    //     if (import.meta.env.DEV) {
    //         // note, read through this for the following lines of importing
    //         // https://github.com/vitejs/vite/issues/4945
    //         const comps = import.meta.glob('./Pages/**/*.vue');
    //         const match = comps[`./Pages/${name}.vue`];
    //         const page = (await match()).default;
    //         setupLayout(page, name);
    //         return page;
    //     } else {
    //         let pages = asyncViews();
    //         const importPage = pages[`./Pages/${name}.vue`];
    //         return importPage()
    //             .then((module) => {
    //                 let page = module.default;
    //                 setupLayout(page, name);
    //                 return page;
    //             })
    //             .catch(() => {
    //                 // e.message is something like "TypeError: Failed to fetch dynamically imported module:"
    //                 // we are here because the user's browser is trying to pull in a component (with hashed name)
    //                 // but we have just deployed a new version (with a new hash).  Try to determine where they were going
    //                 // and redirect their browser in a way to force a reload of the page.
    //
    //                 let routeName = '';
    //                 //we're only given the name of the component.  Try to find the route they were trying to load
    //                 switch (name) {
    //                     case 'Announcement':
    //                     case 'Announcements':
    //                         routeName = 'announcements.index';
    //                         break;
    //                     case 'ApprovalPath':
    //                     case 'ApprovalPaths':
    //                         routeName = 'approval-paths.index';
    //                         break;
    //                     case 'Authority':
    //                     case 'Authorities':
    //                         routeName = 'authorities.index';
    //                         break;
    //                     case 'Document':
    //                     case 'Documents':
    //                         routeName = 'documents';
    //                         break;
    //                     case 'Organization':
    //                     case 'Organizations':
    //                         routeName = 'organizations.index';
    //                         break;
    //                     case 'Resource':
    //                     case 'Resources':
    //                         routeName = 'resources.index';
    //                         break;
    //                     case 'Admin':
    //                         routeName = 'admin.index';
    //                         break;
    //                 }
    //
    //                 const timestamp = new Date().getTime(); // something unique to tack onto the url to force the browser to reload
    //                 let href;
    //
    //                 if (route().has(routeName)) {
    //                     href = route(routeName);
    //                 } else {
    //                     href = '';
    //                 }
    //                 console.log('redirecting...', `${href}/?${timestamp}`);
    //                 window.location.replace(`${href}/?${timestamp}`);
    //             });
    //     }
    // },
    // setup({ el, App, props, plugin }) {
    //     const emitter = mitt();
    //
    //     const app = createApp({
    //         render: () => h(App, props),
    //     });
    //
    //     registerGlobalComponents(app);
    //
    //     app.provide('emitter', emitter);
    //     app.use(plugin);
    //
    //     //register honeyBadger if not in development
    //     if (honeyBadgerConfig.environment!=='development') {
    //         app.use(HoneybadgerVue, honeyBadgerConfig);
    //     }
    //
    //     app.mixin({
    //             methods: {
    //                 route, // eslint-disable-line no-undef
    //             },
    //         });
    //
    //     app.mount(el);
    // },

    title: (title) => (title ? `${title} - ARMS` : `ARMS`),
}).then(r =>{});
