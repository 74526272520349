<script setup>
import Avatar from './Avatar.vue';
import {onUnmounted, ref} from 'vue';
import { router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3'

const dropdownOpen = ref(false);
const auth = usePage().props.auth;

onUnmounted(
    router.on('start', () => {
        dropdownOpen.value = false; //user is navigating away, close the dropdown
    })
);

</script>
<template>
    <button
        class="relative z-10 block h-8 w-8 overflow-hidden rounded-full shadow focus:outline-none"
        @click="dropdownOpen = !dropdownOpen"
    >
        <avatar :initials="auth.user.initials" />
    </button>

    <div
        v-show="dropdownOpen"
        class="fixed inset-0 z-10 h-full w-full"
        @click="dropdownOpen = false"
    ></div>

    <transition
        enter-active-class="transition duration-150 ease-out transform"
        enter-from-class="scale-95 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transition duration-150 ease-in transform"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 opacity-0"
    >
        <div
            v-show="dropdownOpen"
            class="absolute right-0 z-20 mt-2 w-48 rounded-md bg-white py-2 shadow-xl dark:border-2 dark:border-gray-600 dark:bg-gray-800"
        >
            <inertia-link
                as="button"
                :href="route('users.show', auth.user.id)"
                class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
                @click="dropdownOpen = false"
                >Profile</inertia-link
            >
            <!--            <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
                >Products</a
            >-->
            <a
                v-if="auth.user.impersonating"
                as="button"
                :href="route('leave-impersonation')"
                @click="dropdownOpen = false"
                class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
                >Stop Impersonating</a
            >
            <inertia-link
                v-else
                as="button"
                :href="route('logout')"
                method="post"
                class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
            >Log out</inertia-link
            >
        </div>
    </transition>
</template>
