<script setup>
import { computed } from 'vue';
import IconTrash from '~icons/fa6-solid/trash';
import IconFloppyDisk from '~icons/fa6-solid/floppy-disk';
import IconAnglesRight from '~icons/fa6-solid/angles-right';
import IconXMark from '~icons/fa6-solid/xmark';
import IconPlus from '~icons/fa6-solid/plus';
import IconCheck from '~icons/fa6-solid/check';
import IconCheckered from '~icons/fa6-solid/flag-checkered';

const props = defineProps({
    type: {
        type: String,
        default: 'button',
    },
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['3xs', '2xs', 'xs', 'sm', 'md', 'lg', 'xl'].includes(value);
        },
    },
    icon: {
        type: String,
        default: '',
        validator(value) {
            return ['', 'save', 'trash', 'forward', 'x', 'plus', 'check', 'flag'].includes(value);
        },
    },
    colorStyle: {
        type: String,
        default: 'primary',
        validator(value) {
            return ['primary', 'secondary', 'success', 'danger', 'warning', 'info'].includes(value);
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['click']);

const buttonColorClass = computed(() => {
    switch (props.colorStyle) {
        case 'primary':
            return [
                'text-white',
                'dark:disabled:text-gray-500',
                'bg-primary-400',
                'hover:bg-primary-200',
                'dark:hover:bg-gray-700',
                'disabled:bg-neutral-400',
                'dark:bg-gray-500',
                'dark:disabled:bg-gray-300',
                'dark:hover:bg-gray-900',
            ];
        case 'secondary':
            return ['bg-secondary-500', 'text-primary', 'hover:bg-secondary-600'];
        case 'success':
            return ['bg-emerald-400', 'text-white', 'hover:bg-emerald-500', 'disabled:bg-emerald-200'];
        case 'danger':
            return [
                'text-white',
                'bg-red-400',
                'disabled:bg-neutral-300',
                'hover:bg-red-500'
            ];
        case 'warning':
            return ['bg-yellow-500', 'text-white'];
        case 'info':
            return ['bg-sky-500', 'text-white', 'hover:bg-sky-600'];
        default:
            return '';
    }
});

const buttonSizeClass = computed(() => {
    // by size
    switch (props.size) {
        case '3xs':
            return ['px-1', 'py-1', 'font-xs', 'rounded'];
        case '2xs':
            return ['px-1', 'py-1', 'font-xs', 'rounded'];
        case 'xs':
            return ['px-2', 'py-1.5', 'text-xs', 'rounded'];
        case 'sm':
            return ['px-3', 'py-2', 'text-sm', 'rounded-md'];
        case 'md':
            return ['px-4', 'py-2', 'text-sm', 'rounded-md'];
        case 'xl':
            return ['px-6', 'py-3', 'text-base', 'rounded-md'];
        default: // lg
            return ['px-4', 'py-2', 'text-base', 'rounded-md'];
    }
});

const iconSizeClass = computed(() => {
    switch (props.size) {
        case '3xs':
            return ['h-2.5 w-2.5'];
        case '2xs':
            return ['h-2.5 w-2.5'];
        case 'xs':
            return ['h-3 w-3'];
        case 'sm':
            return ['h-4 w-4'];
        case 'md':
            return ['h-5 w-5'];
        case 'xl':
            return ['h-5 w-5'];
        default: // lg
            return ['h-5 w-5'];
    }
});
</script>

<template>
    <button
        v-bind="$attrs"
        :class="[buttonSizeClass, buttonColorClass]"
        class="inline-flex cursor-pointer justify-center border border-transparent font-medium shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-200 focus:ring-offset-2 disabled:cursor-auto"
        :type="type"
        :disabled="disabled"
        @click="emit('click')"
    >
        <template v-if="icon">
            <icon-trash v-if="icon === 'trash'" :class="iconSizeClass" />
            <icon-floppy-disk v-if="icon === 'save'" :class="iconSizeClass" />
            <icon-angles-right v-if="icon === 'forward'" :class="iconSizeClass" />
            <icon-x-mark v-if="icon === 'x'" :class="iconSizeClass" />
            <icon-plus v-if="icon === 'plus'" :class="iconSizeClass" />
            <icon-check v-if="icon === 'check'" :class="iconSizeClass" />
            <icon-checkered v-if="icon === 'flag'" :class="iconSizeClass" />

            <div v-if="icon && $slots.default" class="pr-2"></div>
        </template>
        <template v-else>
            <div :class="iconSizeClass" class="inline !w-0"></div>
        </template>
        <slot />
    </button>
</template>
