<script setup>
import {  computed, onUnmounted, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3'
import { ziggyRoute } from "../../Hooks/useZiggy";
import TwDropdown from '../TwDropdown.vue';
import TwDropdownItem from '../TwDropdownItem.vue';
import IconBell from '~icons/bxs/bell';
import IconBellRing from '~icons/bxs/bell-ring';
import IconMegaphone from '~icons/fluent/megaphone-loud-20-regular';
import IconResources from '~icons/carbon/document-attachment';
import axios from 'axios';

const dropdownOpen = ref(false);

const alerts = computed(() => usePage().props.auth.user.alerts);

onUnmounted(
    router.on('start', () => {
        dropdownOpen.value = false; //user is navigating away, close the dropdown
    })
);

const markRead = async () => {
    await axios.post(ziggyRoute('alerts.read'));
}
</script>
<template>
    <tw-dropdown v-if="alerts.length>0" class="inline" @click="markRead">
        <template #icon>
            <icon-bell-ring
                :class="'text-rose-800 dark:text-rose-600'"
                class="animate-[pulse_1s_ease-in-out_2] ml-3 mt-1.5 h-7 w-8 rounded-full"
            />
        </template>
        <tw-dropdown-item
            v-for="alert in alerts"
            as="button"
            :href="alert.href"
            class="block w-full whitespace-nowrap px-0 py-0 text-left text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
            @click="dropdownOpen = false"
        >
            <div class="flex place-items-center">
                <icon-megaphone v-if="alert.type==='announcement'" class="inline flex-0 mr-4 text-lg " />
                <icon-resources v-else class="inline flex-0 mr-4 text-lg " />
            <div>
            {{ alert.title }}
            <div class="italic">{{ alert.created_at }}</div>
            </div>
            </div>
        </tw-dropdown-item
        >
    </tw-dropdown>
    <icon-bell
        v-else
        class="ml-3 h-7 w-8 rounded-full"
    />

</template>
