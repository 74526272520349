<script setup>
import { computed } from 'vue';
import {
    Dialog,
    DialogOverlay,
    //DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import IconXMark from '~icons/fa6-solid/xmark';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    // open: {
    //     type: Boolean,
    //     default: false,
    // },
    size: {
        type: String,
        default: 'md',
    },
});

const sizeClass = computed(() => {
    if (props.size === 'sm') {
        return 'sm:max-w-md';
    }
    if (props.size === 'md') {
        return 'sm:max-w-xl';
    }
    if (props.size === 'lg') {
        return 'sm:max-w-3xl';
    }
    if (props.size === 'xl') {
        return 'sm:max-w-5xl';
    }
    return '';
});

const emits = defineEmits(['update:modelValue']);

function hideModal() {
    //emits('hideModal');
    emits('update:modelValue', false);
}
</script>

<template>
    <TransitionRoot as="template" :show="modelValue">
        <Dialog as="div" class="fixed inset-0 z-[1100] overflow-y-auto" @close="hideModal()">
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        :class="sizeClass"
                        class="relative inline-block transform divide-y divide-gray-200 overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:align-middle"
                    >
                        <div
                            v-if="$slots.header"
                            class="flex items-center justify-between px-4 py-4 text-gray-600 sm:px-6"
                        >
                            <slot name="header"></slot>
                            <button
                                type="button"
                                class="rounded-full bg-white leading-none text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-primary-200 focus:ring-offset-2"
                                @click="hideModal()"
                            >
                                <icon-x-mark class="h-5 w-5" />
                            </button>
                        </div>

                        <div class="px-4 pt-2 pb-5">
                            <slot></slot>
                        </div>

                        <div
                            v-if="$slots.footer"
                            class="rounded-b-lg bg-gray-50 px-4 py-4 text-sm text-gray-500 sm:px-6"
                        >
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
