<template>
    <div>
        <input
            :id="value"
            v-bind="$attrs"
            ref="input"
            class="form-radio mr-2 h-5 w-5 text-gray-600"
            type="radio"
            :disabled="disabled"
            :required="name && required"
            :name="name"
            :value="value"
            :checked="isChecked"
            @click.stop="handleClick($event.target.checked)"
            @focus="focus"
            @blur="blur"
        />
        <label
            v-if="$slots.default || !plain"
            class="mt-3 inline-flex items-center text-sm font-medium text-gray-700 dark:text-white"
            :for="value"
            :class="[{ active: isChecked, focus: isFocused }]"
        >
            <slot />
        </label>
    </div>
</template>

<script lang="ts">
// https://github.com/cdmoro/bootstrap-vue-3/blob/main/src/components/BFormRadio/BFormRadio.vue
import { computed, defineComponent, ref, watch } from 'vue';
export default defineComponent({
    props: {
        modelValue: {
            type: [Boolean, String, Array, Object, Number],
            default: null,
        },
        plain: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        id: { type: String },
        name: { type: String },
        required: { type: Boolean, default: false },
        value: { type: [String, Boolean, Object, Number], default: true },
    },
    emits: ['update:modelValue', 'change', 'input'],
    setup(props, { emit }) {
        const input = ref(null);
        const isFocused = ref(false);
        const localChecked = computed({
            get: () => props.modelValue,
            set: (newValue) => {
                emit('input', newValue);
                emit('change', newValue);
                emit('update:modelValue', newValue);
            },
        });
        const focus = () => {
            isFocused.value = true;
            if (!props.disabled) input.value.focus();
        };
        const blur = () => {
            isFocused.value = false;
            if (!props.disabled) {
                input.value.blur();
            }
        };
        const isChecked = computed(() => {
            if (Array.isArray(props.modelValue)) {
                return (props.modelValue || []).find((e) => e === props.value);
            }
            return (
                JSON.stringify(props.modelValue) === JSON.stringify(props.value)
            );
        });
        const handleClick = async (checked) => {
            if (Array.isArray(props.modelValue)) {
                if ((props.modelValue || [])[0] !== props.value) {
                    localChecked.value = [props.value];
                }
            } else if (checked && props.modelValue !== props.value) {
                localChecked.value = props.value;
            }
        };
        watch(
            () => props.modelValue,
            (newValue) => {
                emit('input', newValue);
            }
        );

        return {
            localChecked,
            isChecked,
            isFocused,
            input,
            handleClick,
            focus,
            blur,
        };
    },
});
</script>
