<script setup>
/*
TwAutoComplete

<tw-auto-complete
    v-model="clientData.emr_type"
    label="Emr Type"
    placeholder="Select Emr Type"
    multiple
    :select-items="[
        { value: 'luvo_emr', label: 'Luvo EMR'},
        { value: 'epic', label: 'Epic'},
        { value: 'athena', label: 'Athenahealth'},
    ]"
    :error="form.errors.emr_type"/>


Just to keep compatibility open, you can pass select-items attribute as an array of objects with value||id or text||label keys.
    [{ value: 'luvo_emr', label: 'Luvo EMR'}, ... ]
** OR **
    [{ id: 'luvo_emr', text: 'Luvo EMR'}, ... ]
 */

import { computed, onMounted, ref, watch, useSlots } from 'vue';
import { onClickOutside } from '@vueuse/core';
import SelectorIcon from '~icons/heroicons-outline/selector';
import IconXMark from '~icons/fa6-solid/xmark';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual.js';

const emit = defineEmits(['update:modelValue']);
const slots = useSlots();
const hasSlot = (name) => !!slots[name];
const ul_list = ref(null); // ref on the <ul> element of the dropdown list so, on arrow up/down we can scroll it

const props = defineProps({
    // v-model is the string value of the list item id property (item.id)
    modelValue: {
        type: [String, Number, Array],
        default: '',
    },
    name: {
        type: String,
        default: '',
    },
    // array of list item objects [{text:'', id:''}]
    //   OR
    // array of strings ['Jason','Shirley','Rob']
    selectItems: {
        type: Array,
        default: () => [],
    },
    // what to display in text field when empty
    placeholder: {
        type: String,
        default: 'Search',
    },
    // pass in an error string to display field as error (typically this is form.errors.field_name)
    error: {
        type: String,
        default: '',
    },
    // label for field
    label: {
        type: String,
        default: '',
    },
    hideLabel: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
});

const tags = ref([]); //track which tags the user has selected
const tagsMode = computed(() => props.multiple); //multi-select/tags mode
const showSearchItems = ref(false); //when to show/hide search results
const inputValue = ref({ text: '', id: '' }); //value of input field in normal (non-tags) mode
const autoCompleteRef = ref(null); //ref on the autocomplete element so we can track outside clicks to close it
const activeItem = ref(null); //which item in dropdown is active when keyboard up/down is used
const makeDropdownFiltered = ref(false); // we track when we should show the filtered list vs the full list

// hide search items, unselect the currently selected/highlighted item
const hideMenu = () => {
    showSearchItems.value = false;
    activeItem.value = null;
};

// user clicked outside the autocomplete menu, hide it
onClickOutside(autoCompleteRef, () => {
    hideMenu();
});

const arrowDown = () => {
    showSearchItems.value = true; //show the list if it's not already visible

    if (activeItem.value !== null) {
        //only increase if we're not at the end of the list
        if (activeItem.value < filteredList.value.length - 1)
            activeItem.value++;

        scrollListItemIntoView();
    } else {
        activeItem.value = 0;
    }
};

const arrowUp = () => {
    if (activeItem.value) activeItem.value--;

    scrollListItemIntoView();
};

const scrollListItemIntoView = () => {
    ul_list.value.children //children are the <li> elements
        .item(activeItem.value) //grab the <li> element that is active
        .scrollIntoView({ block: 'nearest' }); //scroll it into view
};

const selectItemsNormalized = computed(() => {
    // if we are passed an array of strings, convert into {text:'', id:'', disabled: bool}
    //   format so that we can deal with it internally

    if (props.selectItems.length > 0) {
        if (typeof props.selectItems[0] === 'string') {
            return props.selectItems.map((e) => {
                return {
                    text: e,
                    id: e,
                    disabled: tagsMode.value
                        ? itemsPicked.value.includes(e)
                        : false,
                };
            });
        } else {
            // look for {label:'', value:''} format and convert to {text:'', id:''} format
            return props.selectItems.map((e) => {
                return {
                    text: e.text || e.label,
                    id: e.id || e.value,
                    disabled: tagsMode.value
                        ? itemsPicked.value.includes(e.id || e.value)
                        : false,
                };
            });
        }
    }
    return props.selectItems;
});

// reduce down to array of ids/values instead of array of objects for each determination of whether an item is selected
const itemsPicked = computed(() => {
    if (tagsMode.value) {
        return tags.value.map((tag) => tag.id) || [];
    } else {
        return [props.modelValue];
    }
});

// filter what to show based on what the user has typed
const filteredList = computed(() => {
    if (!makeDropdownFiltered.value) return selectItemsNormalized.value; //show the full list

    return selectItemsNormalized.value.filter((item) => {
        return item.text
            .toLowerCase()
            .includes(inputValue.value.text.toLowerCase());
    });
});

const hasError = computed(() => {
    return Boolean(props.error);
});

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue !== inputValue.value.id) {
            setInputValue();
        }
    }
);

watch(
    () => inputValue.value.text,
    () => {
        // check to see if user is zeroing out the field / removing current selection
        if (inputValue.value.text === '') {
            inputValue.value.id = '';
            makeDropdownFiltered.value = false; // user clearing field, show all options
        } else {
            makeDropdownFiltered.value = true; // user typing, show filtered list
        }
    }
);

// fill the input field with the normalized object that matches the id
// or fill the tags array with the normalized objects that match the id
const setInputValue = () => {
    if (tagsMode.value) {
        tags.value = intersectionWith(
            selectItemsNormalized.value,
            props.modelValue,
            (a, b) => a.id === (b.id || b.value)
        );
    } else {
        // non-tags mode
        const matchingRecord = selectItemsNormalized.value.find(
            (e) => e.id === props.modelValue
        );
        if (matchingRecord)
            inputValue.value = Object.assign({}, matchingRecord);
    }
};

// watch tags and emit modelValue when they change
watch(
    () => [...tags.value],
    (currentValue, oldValue) => {
        //console.log('watch tags', currentValue, oldValue);
        if (
            tagsMode.value &&
            (currentValue.length > 0 || oldValue.length > 0)
        ) {
            // emit in the same {} shape as the selectItems (provided by calling component)
            const emitItems = props.selectItems.filter((item) => {
                return currentValue
                    .map((tag) => tag.id)
                    .includes(item.id || item.value);
            });

            // sometimes props.modelValue is empty string (not an array)
            //console.log(props.modelValue);
            const thereWasAnActualChange =
                (props.modelValue === '' && emitItems.length > 0) ||
                !isEqual(
                    props.modelValue.map((e) => e.id || e.value).sort(),
                    emitItems.map((e) => e.id).sort()
                );

            if (thereWasAnActualChange) emit('update:modelValue', emitItems);
            //makeDropdownFiltered.value = false; // user made a selection, show full list
        }
    }
);

// watch input value (no tags) and emit modelValue when it changes
watch(
    () => inputValue.value.id,
    () => {
        emit('update:modelValue', inputValue.value.id);
        makeDropdownFiltered.value = false; // user made a selection, show full list
    }
);

onMounted(() => {
    setInputValue();
});

const removeTag = (tag) => {
    tags.value.splice(
        tags.value.findIndex((item) => item.id === tag.id),
        1
    );
};

const selectItem = (index) => {
    // if user pressed enter without anything selected but filtered list was down to single element
    if (index === null && filteredList.value.length === 1) index = 0;

    // if selecting an element, make a copy of the object
    if (index !== null)
        if (tagsMode.value) {
            // push array of objects
            const selectedItem = filteredList.value[index];
            if (!tags.value.map((e) => e.id).includes(selectedItem.id)) {
                // not already selected
                tags.value.push(selectedItem);
            }
            inputValue.value.text = ''; // clear input after selection
        } else {
            inputValue.value = Object.assign({}, filteredList.value[index]);
        }
    hideMenu();
};
</script>

<template>
    <div class="contents">
        <label
            v-if="tagsMode"
            class="mb-1 block text-sm text-gray-700 dark:text-white"
            :class="required && !disabled ? 'font-bold' : 'font-medium'"
        >
            <template v-if="hasSlot('label')"
                ><slot name="label"></slot
            ></template>
            <template v-else-if="label"
                >{{ label
                }}<span v-if="required && !disabled && !readOnly"
                    >*</span
                ></template
            >
        </label>

        <div
            :class="[
                tagsMode && !disabled
                    ? 'mt-1 rounded-md border bg-white p-0 dark:bg-gray-600'
                    : 'pb-4',
                showSearchItems && !disabled
                    ? 'border-primary'
                    : 'border-gray-300',
            ]"
        >
            <div
                ref="autoCompleteRef"
                class="relative w-full"
                @keydown.enter.prevent="selectItem(activeItem)"
            >
                <label
                    v-if="!tagsMode"
                    class="mb-2 block text-sm font-normal text-gray-700"
                    :class="required && !disabled ? 'font-bold' : 'font-medium'"
                >
                    <template v-if="hasSlot('label')"
                        ><slot name="label"></slot
                    ></template>
                    <template v-else-if="label">{{ label }}</template>
                </label>

                <div class="relative" :class="tagsMode ? '' : 'mt-1'">
                    <div
                        v-if="!disabled"
                        class="pointer-events-none absolute inset-y-0 right-0 flex h-9 items-center pr-2"
                    >
                        <selector-icon
                            class="h-5 w-5 text-gray-400 dark:text-white"
                            aria-hidden="true"
                        />
                    </div>

                    <input
                        v-if="!tagsMode"
                        ref="searchBox"
                        v-model="inputValue.text"
                        type="text"
                        :disabled="disabled"
                        :class="hasError ? 'border-red-500' : 'border-gray-300'"
                        class="mt-1 block w-full rounded-md placeholder-black shadow-sm focus:border-none focus:ring-1 focus:ring-primary sm:text-sm"
                        :placeholder="placeholder"
                        aria-label="Search"
                        @input="showSearchItems = true"
                        @focus="showSearchItems = true"
                        @click="showSearchItems = true"
                        @keydown.up="arrowUp"
                        @keydown.down="arrowDown"
                        @keyup.esc="hideMenu"
                        @keydown.tab="hideMenu"
                    />

                    <!-- Tags (with search) -->
                    <div v-if="tagsMode" class="w-full">
                        <div class="flex w-full flex-wrap">
                            <div v-if="disabled" class="text-sm">
                                {{
                                    tags
                                        .map((tag) => tag.label || tag.text)
                                        .join(', ')
                                }}
                            </div>

                            <div v-for="tag in tags" v-else class="shrink-0">
                                <div
                                    class="m-[5px] rounded-md border border-gray-300 bg-gray-200 p-1 text-xs dark:bg-gray-500"
                                >
                                    {{ tag.label || tag.text }}
                                    <div
                                        v-if="!disabled"
                                        class="inline cursor-pointer"
                                        @click="removeTag(tag)"
                                    >
                                        <icon-x-mark
                                            class="inline h-3 w-3 pb-0.5 text-gray-500 dark:text-gray-200"
                                        />
                                    </div>
                                </div>
                            </div>

                            <input
                                v-if="!disabled"
                                ref="searchBox"
                                v-model="inputValue.text"
                                type="text"
                                class="w-1/4 grow rounded-md border-none shadow-sm focus:ring-0 dark:bg-gray-600 sm:text-sm"
                                :disabled="disabled"
                                :placeholder="
                                    tags.length === 0 ? placeholder : null
                                "
                                aria-label="Search"
                                @input="showSearchItems = true"
                                @focus="showSearchItems = true"
                                @click="showSearchItems = true"
                                @keydown.up="arrowUp"
                                @keydown.down="arrowDown"
                                @keyup.esc="hideMenu"
                                @keydown.tab="hideMenu"
                            />
                        </div>
                    </div>
                </div>

                <aside
                    v-if="
                        !disabled && filteredList.length > 0 && showSearchItems
                    "
                    class="absolute z-10 mt-1 flex max-h-60 w-full flex-col items-start overflow-auto rounded-md border border-gray-300 bg-white shadow-lg dark:bg-gray-200 sm:text-sm"
                    role="menu"
                    aria-labelledby="menu-heading"
                >
                    <ul ref="ul_list" class="flex w-full flex-col">
                        <li
                            v-for="(item, index) in filteredList"
                            :id="'ac_' + index + '_' + name"
                            :key="index"
                            class="py-2 pl-3 pr-9"
                            :class="[
                                index === activeItem
                                    ? 'bg-gray-600 text-white'
                                    : 'dark:text-black',
                                item.disabled
                                    ? 'cursor-not-allowed italic opacity-50 dark:opacity-70'
                                    : '',
                            ]"
                            @mouseover="activeItem = index"
                            @click="!item.disabled ? selectItem(index) : null"
                        >
                            {{ item.text }}
                        </li>
                    </ul>
                </aside>
            </div>
        </div>
        <div v-if="hasError" class="text-sm italic text-red-500">
            {{ error }}
        </div>
    </div>
</template>
