import { Link } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'

import WinButton from './Shared/WinButton.vue';
import WinInput from './Shared/Inputs/WinInput.vue';
import WinSelect from './Shared/Inputs/WinSelect.vue';
import WinAutoComplete from './Shared/Inputs/WinAutoComplete.vue';
import WinTable from './Shared/WinTable.vue';
import WinRadio from './Shared/Inputs/WinRadio.vue';
import FloatingVue from 'floating-vue';

export const registerGlobalComponents = (app) => {
    app.component('InertiaLink', Link);
    app.component('InertiaHead', Head);
    app.component('WinButton', WinButton);
    app.component('WinInput', WinInput);
    app.component('WinSelect', WinSelect);
    app.component('WinAutoComplete', WinAutoComplete);
    app.component('WinTable', WinTable);
    app.component('WinRadio', WinRadio);
    app.use(FloatingVue);
};
