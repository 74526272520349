<script setup>
import { inject, ref } from 'vue';

import { usePage } from '@inertiajs/vue3'
import IconDownload from '~icons/fa-solid/file-download';

const disabled = ref(false);
const emitter = inject('emitter');

const initiateExport = async () => {
    disabled.value = true;
    emitter.emit('toast', 'Export started... Please wait.');

    setTimeout(() => {
        disabled.value = false;
    }, 2500);
};
</script>

<template>
    <div>
        <win-button @click="initiateExport" :disabled="disabled">
            <a v-if="!disabled" download :href="'/export' + usePage().url">
                <icon-download class="mr-1 inline h-5 w-5" />
                <div class="inline align-middle">EXPORT</div>
            </a>
            <div v-else class="inline align-middle">
                <icon-download class="mr-1 inline h-5 w-5" />
                <div class="inline align-middle">EXPORT</div>
            </div>
        </win-button>
    </div>
</template>
