<script setup>
import { ref, watch } from 'vue';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import IconDown from '~icons/heroicons-solid/chevron-down';

defineProps({
    hideIcon: {
        type: Boolean,
        default: false,
    },
});

const initiateDropdown = () => {
    menuButton.value.el.click();
};
const menuButton = ref();
const menuItems = ref();

defineExpose({
    initiateDropdown,
});

const emit = defineEmits(['open', 'close']);

watch(
    () => menuItems.value,
    (newValue) => {
        emit(newValue ? 'open' : 'close');
    }
);
</script>

<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                ref="menuButton"
                class="inline-flex w-full justify-center text-sm font-medium text-gray-700 focus:outline-none"
            >
                <template v-if="$slots.icon">
                    <slot name="icon" />
                </template>
                <template v-else-if="!hideIcon">
                    <icon-down
                        class="-mr-1 ml-2 h-3 w-3 text-gray-700 dark:text-white"
                        aria-hidden="true"
                    />
                </template>
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-50 mt-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-500"
            >
                <div
                    ref="menuItems"
                    class="relative max-h-[85vh] overflow-y-auto py-1"
                >
                    <slot></slot>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
