<script>
// use normal <script> to declare options
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { useClipboard } from '@vueuse/core/index';
import IconCopy from '~icons/fa6-solid/copy';
import { ref } from 'vue';

const props = defineProps({
    text: {
        type: String,
        default: '',
    },
    size: {
        type: [Number, String],
        default: 4,
    },
});

const { copy, isSupported } = useClipboard();
const copyPopper = ref();

const copyToClipboard = () => {
    if (isSupported) {
        copy(props.text);
        setTimeout(() => {
            copyPopper.value.hide();
        }, 2000);
    }
};
</script>
<template>
    <VDropdown
        v-if="isSupported"
        ref="copyPopper"
        title="Copy to clipboard"
        class="inline"
        :delay="{ show: 100, hide: 0 }"
    >
        <icon-copy
            v-bind="$attrs"
            class="inline cursor-pointer text-gray-600 hover:text-gray-400"
            :class="`h-${size} w-${size}`"
            @click="copyToClipboard"
        />
        <template #popper><div class="p-1 text-sm">Copied!</div></template>
    </VDropdown>
</template>
